export const useColumnCount = (
  configConstant: { [key: string]: { [key: string]: number } },
  devices: string[],
) => {
  const isXs = inject<Ref<boolean>>('isXs');
  const isMobile = inject<Ref<boolean>>('isMobile');

  return computed(() => {
    const config = configConstant;

    if (!config) {
      throw new Error('Config constant is not provided');
    }

    if (isXs?.value && devices.includes('isXs')) {
      return config.xs.columns;
    } else if (isMobile?.value && devices.includes('isMobile')) {
      return config.mobile.columns;
    } else {
      return config.desktop.columns;
    }
  });
};
