<template>
  <div class="j-content-items-grid">
    <div
      :style="gridStyle"
      class="j-content-items-grid__content"
    >
      <div
        v-for="(itemData, index) in items"
        :key="`${index}${(itemData as GameItem).prettyName}`"
      >
        <general-content-game-card
          v-if="showGameItems"
          :img-src="getGameImgSrc(itemData as GameItem)"
          :game-name="(itemData as GameItem).gameName"
          :display-policy="(itemData as GameItem).displayPolicy || (itemData as GameItem).displayPolicyName"
          :pretty-name="(itemData as GameItem).prettyName"
          :provider="(itemData as GameItem).gameProviderId"
          :label="(itemData as GameItem).label"
          :demo-mode-support="(itemData as GameItem).demoModeSupport"
          :allowed-mode="allowedMode"
        />
        <general-content-card
          v-else
          :id="(itemData as NewsItem).id"
          :img-src="(itemData as NewsItem).previewNewsImgUrl"
          :title="(itemData as NewsItem).title"
          :description="(itemData as NewsItem).shortText"
          :show-description="showDescription"
        />
      </div>
    </div>
    <div
      v-if="loading"
      class="text-center j-content-items-grid__loader"
    >
      <v-progress-circular
        :size="50"
        :color="scssVariables.jColorPrimary"
        indeterminate
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GameItem } from '~/types/general/game';
import type { NewsItem } from '~/types/general/news';
import type { AllowedMode } from '~/types/components/General/ContentGameCard';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  remoteStorageHost: {
    type: String,
    default: '',
  },
  columnCount: {
    type: Number,
    default: 0,
  },
  publicImgStoreDirName: {
    type: String,
    default: '',
  },
  columnGap: {
    type: Number,
    default: 0,
  },
  allowedMode: {
    type: String as () => AllowedMode,
    default: undefined as AllowedMode,
  },
  showDescription: {
    type: Boolean,
    default: false,
  },
});
const {
  items,
  remoteStorageHost,
  loading,
  columnCount,
  publicImgStoreDirName,
  columnGap,
} = toRefs(props);
const getGameImgSrc = (itemData: GameItem) => {
  let appStorageSrc;

  if (publicImgStoreDirName?.value) {
    appStorageSrc =
      publicImgStoreDirName?.value === 'games'
        ? `/images/${publicImgStoreDirName?.value}/${itemData.provider
          || itemData.externalProviderCode}/${itemData?.gameCode}.jpg`
        : `/images/${publicImgStoreDirName?.value}/${itemData?.gameCode}.jpg`;
  } else {
    appStorageSrc = images[itemData?.gameCode];
  }

  return itemData?.iconPath
    ? `${remoteStorageHost.value}${itemData?.iconPath}`
    : appStorageSrc;
};
const scssVariables = useScssVariables();
const images = useAssetsImages();
const gridStyle = computed(() => ({
  'grid-template-columns': `repeat(${columnCount.value}, 1fr)`,
  gap: `${columnGap.value}px`,
}));
const showGameItems = computed(() => publicImgStoreDirName?.value === 'games');
</script>

<style lang="scss" scoped>
.j-content-items-grid {
  position: relative;
}
.j-content-items-grid__content {
  display: grid;
}
.j-content-items-grid__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
